import request from '@/plugins/axios'


// 分类列表
export const apiCommunityCategoryLists = (params: any) => request.get('/community.community_category/lists', { params })

// 添加分类
export const apiCommunityCategoryAdd = (params: any) => request.post('/community.community_category/add', params)

// 编辑分类
export const apiCommunityCategoryEdit = (params: any) => request.post('/community.community_category/edit', params)

// 删除分类
export const apiCommunityCategoryDelete = (params: any) => request.post('/community.community_category/delete', params)

// 分类详情
export const apiCommunityCategoryDetail = (params: any) => request.get('/community.community_category/detail', { params })

export const apiCommunityCategorySwitchStatus = (params: any) => request.post('/community.community_category/status', params)